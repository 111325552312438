.cloud-accounts-container {
  margin-bottom: 100px;

  .page-header-buttons {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}
.section {
  margin: 20px 0;
}
.buttons {
  margin: 20px 0;
  display: flex;
  span + span {
    margin-left: 20px;
  }
}

.header-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 0 20px 0;

  h4 {
    margin: 0px;
  }
}

.force-margin-top-small {
  margin-top: 20px;
}

label + label {
  margin-left: 20px;
  input {
    margin-right: 5px;
  }
}

.section-group {
  display: flex;

  &.center {
    justify-content: center;
  }

  & + .section-group {
    margin-top: 15px;
  }

  div:not(.display-flex) {
    flex: 1;

    & + div {
      padding-left: 20px;
    }
    &.input-error-text {
      padding-left: 0;
    }
  }
}

label {
  textarea,
  input[type='text'] {
    display: block;
  }

  textarea {
    width: 100%;
  }
}

.display-flex {
  display: flex;

  &.space-between {
    justify-content: space-between;
  }
}

.display-contents {
  width: 50%;
  flex-direction: column;

  &.space-between {
    justify-content: space-between;
  }
}

.text-right {
  text-align: right;
}

.current-pill {
  background: #0078bd;
  border-radius: 10px;
  padding: 2px 6px;
  display: inline-block;
  margin: 0px 10px;
  font-size: 13px;
  color: white;
}

input[type='radio'],
input[type='checkbox'] {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  padding: 0;
  top: 1px;
  position: relative;
}

input {
  + span .btn {
    margin-left: 15px;
  }
}

.edit-modal-body {
  min-height: 110px;
}

.small-margin-left {
  margin-left: 10px;
}

.invisible-take-space {
  opacity: 0;
}

@media (min-width: 567px) {
  .sidebar {
    position: fixed;
    top: 0;
    bottom: 0;
    width: 8rem !important;
    z-index: 1030;
  }

  .mainbar {
    margin-left: 8rem;
  }
}
