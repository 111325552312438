.form-error,
.input-error-text {
  color: #8d3433;
}

.form-control {
  &.error {
    color: #495057;
    background-color: #fff;
    border-color: #8d3433;
    outline: 0;
    -webkit-box-shadow: 0 0 0 0.2rem rgb(227 18 75 / 25%);
    box-shadow: 0 0 0 0.2rem rgb(227 18 75 / 25%);
  }

  &.medium {
    max-width: 240px;
  }
}

.input-error-text {
  text-transform: uppercase;
  margin: 5px;
  display: inline-block;
}

.input-validating-container {
  color: $gray-700;
  margin: 5px;
}
