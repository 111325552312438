.nav-tabs {
  .nav-link {
    &.active {
      font-weight: 600;
      background: #007bc2;
      color: #fff;
      pointer-events: none;
    }
  }
}
