.ca-badge {
  border-radius: 10px;
  padding: 2px 8px;
  font-size: 14px;
  font-weight: 200;
  &.blue {
    background-color: #2c3a81;
    color: #ffffff;
  }
}
