.link-button {
  margin: 0px 0px 10px 0;
  border: 0;
  background: 0;
  color: #0029f5;
  font-weight: 600;

  &:hover {
    cursor: pointer;
    color: #1b42ff;
    background-color: #ffffff;
  }
}

input[type='radio'],
input[type='checkbox'] {
  margin-right: 5px;
}
