.requests-table {
  width: 100%;

  tr {
    border: 1px solid #dedede;
    margin: 10px 0;
    td,
    th {
      padding: 5px;
    }
  }

  .no-action-row {
    &:hover {
      background-color: #f3f3f3;
    }
  }

  .action-row {
    &:hover {
      background-color: #f3f3f3;
      cursor: pointer;
    }
  }
}
