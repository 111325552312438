label {
  font-weight: 400;
}

.emph {
  color: #6b6b6b;
  font-size: 13px;
}

.loading-text {
  color: #6b6b6b;
}
