.changed-section {
  padding-bottom: 20px;
  border-bottom: 1px solid #cccc;

  &:last-of-type {
    border-bottom: 0 !important;
  }
  strong {
    font-weight: 600;
  }
  + .changed-section {
    margin-top: 20px;
  }

  .changes {
    margin-top: 15px;
  }
}
