.modal-data-container {
  display: flex;
  flex-wrap: wrap;
  padding: 20px 0px;
  justify-content: space-evenly;
  div {
    flex-grow: 1;
    flex-basis: 0;
  }
  .break {
    flex-basis: 100%;
    height: 0;
    margin-bottom: 20px;
  }

  h5 {
    font-size: 15px;
    color: #6b6b6b;
    font-weight: 500;
  }
}

.detail-provider-container {
  img {
    width: 32px;
    margin: 0 10px 0 0;
  }
}
