// @import url('https://use.typekit.net/mrl1qhh.css');
// @import "../../node_modules/bootstrap/scss/bootstrap";
// @import '../../node_modules/react-bootstrap-typeahead/css/Typeahead.css';

@import './vars';
@import './global';

@import './layout';
@import './typography';

@import './components/accordion';
@import './components/badge';
@import './components/buttons';
@import './components/changelog';
@import './components/form';
@import './components/loader';
@import './components/modal';
@import './components/requests';
@import './components/tabs';

.profile-hover .profile img {
  object-fit: cover;
}

.basic-layout .content-area {
  height: auto;
}
