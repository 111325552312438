.accordion + .accordion {
  margin-top: 10px;
}

.accordion-item:first-of-type {
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
}

.accordion-item {
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.125);
}

.accordion-header {
  margin-bottom: 0;
}

.accordion-item:first-of-type .accordion-button {
  border-top-left-radius: calc(0.25rem - 1px);
  border-top-right-radius: calc(0.25rem - 1px);
}

.accordion-button:not(.collapsed) {
  color: #0c63e4;
  background-color: #e7f1ff;
  box-shadow: inset 0 -1px 0 rgb(0 0 0 / 13%);
}

.accordion-button {
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  padding: 0.5rem 1.25rem;
  font-size: 1rem;
  color: #212529;
  text-align: left;
  background-color: #fff;
  border: 0;
  border-radius: 0;
  overflow-anchor: none;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out,
    border-radius 0.15s ease;
}

.min-small {
  width: 100px;
}
.min-medium {
  width: 160px;
  white-space: nowrap;
}

.accordion-body {
  padding: 1rem 1.25rem;
}

.accordion-subheader {
  margin: 0px 40px;

  &.emph {
    color: #6c757d;
    font-size: 0.9rem;
  }
}

.accordion-body {
  .accordion-body-list-item {
    display: flex;
    align-items: center;
    justify-content: space-between;

    border-bottom: 1px solid #dedede;
    margin: 10px 10px;
    padding: 0 0 10px 0;

    &:last-child {
      border-bottom: 0px;
      padding-bottom: 0px;
    }

    .list-item-col {
      display: flex;
      align-items: center;
      div {
        & + div {
          margin-left: 20px;
        }
      }
    }
  }

  .btn:hover {
    color: #005da9 !important;
  }
}

.modal-button {
  .btn:hover {
    color: #005da9 !important;
  }
}

.accordion-button.collapsed {
  .accordion-button-expanded {
    display: none;
  }

  .accordion-button-collapsed {
    display: inline-block;
  }
}

.accordion-button-collapsed {
  display: none;
}

.accordion-button-expanded {
  display: inline-block;
}

.my-accordion-header {
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;

  .text-component {
    display: flex;
    align-items: center;
  }
}
